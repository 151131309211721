<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h2>Set a new password</h2>
      </div>
      <div class="cell small-12 medium-6 medium-offset-3">
        <p>
          Your details:
          {{ $store.state.customer.first_name }} {{ $store.state.customer.last_name }}<br />
          {{ $store.state.customer.email }}
        </p>
      </div>
      <div class="cell small-12 medium-6 medium-offset-3">
        <label>Password</label>
        <input type="password" v-model="password" />
      </div>
      <div class="cell small-12 medium-6 medium-offset-3">
        <label>Repeat password</label>
        <input type="password" v-model="checkPassword" />
      </div>
      <div class="cell small-12 medium-6 medium-offset-3">
        <button @click="savePassword" class="button">Save Password</button>
      </div>
    </div>
    <div @click="message = ''" v-if="message.length > 0" class="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'NoPassword',
  data() {
    return {
      password: '',
      checkPassword: '',
      message: '',
    };
  },
  methods: {
    savePassword() {
      const postData = {};
      postData.password = this.password;
      postData.customer = this.$store.state.customer.id;
      axios.post('/customers/setPassword.json', postData)
        .then((response) => {
          const customerData = {};
          customerData.created = this.$store.state.customer.created;
          customerData.email = this.$store.state.customer.email;
          customerData.first_name = this.$store.state.customer.first_name;
          customerData.id = this.$store.state.customer.id;
          customerData.last_name = this.$store.state.customer.last_name;
          customerData.mobile = this.$store.state.customer.mobile;
          customerData.password_set = true;
          this.message = response.data;
          this.$cookies.set('customer', customerData);
          this.$store.commit('passwordSet');
        });
    },
  },
};
</script>
