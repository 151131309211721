<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h2>Log In</h2>
      </div>
      <div class="cell small-12 medium-6 medium-offset-3">
        <input
          v-model="email"
          class="sign-up-form-input"
          type="text"
          required
          placeholder="Email" />
      </div>
      <div class="cell small-12 medium-6 medium-offset-3">
        <input
          v-model="password"
          class="sign-up-form-input"
          type="password"
          required
          placeholder="Password" />
      </div>
      <div class="cell small-6 medium-3 medium-offset-3">
        <div class="text-center">
          <button
            @click="logInAction"
            class="button rounded-tall expanded">Log In</button>
        </div>
      </div>
      <div class="cell small-6 medium-3">
        <div class="text-center">
          <router-link to="/forgot-password">
            <span class="forgot-password">Forgot password</span>
            </router-link>
        </div>
      </div>
    </div>
    <div class="message" v-if="message.length > 0" @click="message = ''">
      {{ message }}
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'LoggedOut',
  data() {
    return {
      email: '',
      password: '',
      message: '',
    };
  },
  methods: {
    logInAction() {
      const postData = {};
      postData.email = this.email;
      postData.password = this.password;
      axios.post('/customers/login.json', postData)
        .then((response) => {
          if (response.data === false) {
            this.message = 'Those details were not recognised. Please try again.';
          } else {
            this.$store.dispatch('setCredentials', response.data);
            this.$store.commit('signedUp');
            this.$cookies.set('signedUp', 1);
            this.$store.commit('customer', response.data.customer);
            this.$cookies.set('customer', response.data.customer);
            if (this.$route.name !== 'Profile') {
              this.$router.push('/profile');
            }
          }
        });
    },
  },
};
</script>
