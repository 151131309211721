<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <p class="text-blue text-heavy">
          Name: {{ customer.first_name }} {{ customer.last_name }}<br />
          Email: {{ customer.email }}
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-6 small-offset-3 medium-4 medium-offset-4">
        <router-link to="previous-orders">
          <button class="button ghost white small lower expanded">Previous Orders</button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-6 small-offset-3 medium-4 medium-offset-4">
        <router-link to="/uploaded-artwork">
          <button class="button ghost white small lower expanded">Uploaded Artwork</button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-6 small-offset-3 medium-4 medium-offset-4">
        <button
          @click="logOut"
          class="button ghost white small lower expanded">
          Log Out
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Localbase from 'localbase';

export default {
  name: 'LoggedIn',
  computed: {
    customer() {
      return this.$store.state.customer;
    },
  },
  methods: {
    logOut() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.delete().then(() => {
        this.$store.commit('unsetCredentials');
        window.location.replace('/');
      });
    },
  },
};
</script>
