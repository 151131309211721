<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <h2>Baskets</h2>
        <p>
          You have one or more baskets already saved to your account. Please check if you would
          like to add these items to your current basket
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div
        v-for="(basket, index) in baskets"
        :key="index"
        class="cell small-12">
        <p v-if="basket.id !== parseInt($store.state.basketId)">
          Created on: {{ basket.created | prettyDate }}
        </p>
        <ul v-if="basket.id !== parseInt($store.state.basketId)">
          <li
            v-for="(item, itemIndex) in basket.basket_items"
            :key="itemIndex">
            <span v-if="item.type === 2">
              Design:
              <span v-if="item.design.type === 1">Embroidery</span>
              <span v-else>Screen Print</span>
              {{ item.design.name }}
            </span>
            <span v-if="item.type === 1">
              Garment:
              {{ item.garment_colour_size.garment.short_name }}
              {{ item.garment_colour_size.garment_colour.name }}
              {{ item.garment_colour_size.size }}
              {{ item.quantity }}@
              {{ item.price | currency }}
            </span>
          </li>
        </ul>
        <p v-if="basket.id !== parseInt($store.state.basketId)">
          <button
            @click="mergeBasket(index)"
            class="button small">Add to basket</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ProfileBaskets',
  props: [
    'baskets',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    mergeBasket(index) {
      const theBasketId = this.$store.state.basketId;
      const oldBasketId = this.baskets[index].id;
      axios.get(`/basketItems/mergeBaskets/${theBasketId}/${oldBasketId}.json?token=${this.token}`)
        .then(() => {
          this.baskets.splice(index, 1);
        });
    },
  },
};
</script>
