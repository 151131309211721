<template>
  <div class="grid-container">
    <div class="profile">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center relative">
          <h1 class="logo-text pt-5">
            Customised <span>Clothing</span>
          </h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center">
          <h3 class="pt-5">
            My Profile:
          </h3>
        </div>
      </div>
      <app-profile-logged-in v-if="customerStatus === 1" />
      <app-profile-logged-out v-if="customerStatus === 2" />
      <app-profile-no-password v-if="customerStatus === 3" />
      <app-profile-sign-up
        @loggedIn="customerStatus = 1"
        @exists="customerStatus = 2"
        v-if="customerStatus === 4" />
      <app-profile-baskets
        v-if="customerStatus === 1 && baskets.length > 1"
        :baskets="baskets" />
    </div>
  </div>
</template>

<script>
import profileSignUp from '@/components/profile/SignUp.vue';
import profileNoPassword from '@/components/profile/NoPassword.vue';
import profileLoggedOut from '@/components/profile/LoggedOut.vue';
import profileLoggedIn from '@/components/profile/LoggedIn.vue';
import profileBaskets from '@/components/profile/Baskets.vue';
import axios from '../axios';

export default {
  name: 'Profile',
  components: {
    appProfileSignUp: profileSignUp,
    appProfileNoPassword: profileNoPassword,
    appProfileLoggedOut: profileLoggedOut,
    appProfileLoggedIn: profileLoggedIn,
    appProfileBaskets: profileBaskets,
  },
  data() {
    return {
      customerStatus: 0,
      baskets: [],
    };
  },
  computed: {
    customer() {
      return this.$store.state.customer;
    },
    token() {
      return this.$store.state.token;
    },
    passwordSet() {
      return this.$store.state.customer.password_set;
    },
  },
  watch: {
    token() {
      this.checkLogin();
      this.checkBasket();
    },
    passwordSet() {
      this.checkLogin();
    },
  },
  methods: {
    checkBasket() {
      const theBasketId = this.$store.state.basketId;
      const theCustomerId = this.$store.state.customer.id;
      axios.get(`/customers/checkBasket/${theBasketId}/${theCustomerId}.json?token=${this.token}`)
        .then((response) => {
          this.baskets = response.data;
        });
    },
    checkLogin() {
      if (this.$store.state.token) {
        this.customerStatus = 1;
      } else if (this.$store.state.customer.id) {
        if (this.$store.state.customer.password_set) {
          this.customerStatus = 2;
        } else {
          this.customerStatus = 3;
        }
      } else {
        this.customerStatus = 4;
      }
    },
  },
  mounted() {
    this.checkLogin();
  },
};
</script>
