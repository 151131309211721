<template>
  <div class="grid-container pt-10 pb-10">
    <div class="grid-x grid-padding-x">
      <div class="cell small-6">
        <button @click="showSignUp" class="button expanded">Sign Up</button>
      </div>
      <div class="cell small-6">
        <button @click="showLogIn" class="button expanded">Log In</button>
      </div>
    </div>
    <div v-if="signUp === 1" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h2>Sign Up</h2>
      </div>
      <form @submit.prevent="signUpAction">
        <div class="cell small-12 medium-6 medium-offset-3">
          <input
            v-model="firstName"
            class="sign-up-form-input"
            type="text"
            required
            placeholder="First Name" />
        </div>
        <div class="cell small-12 medium-6 medium-offset-3">
          <input
            v-model="lastName"
            class="sign-up-form-input"
            type="text"
            required
            placeholder="Last Name" />
        </div>
        <div class="cell small-12 medium-6 medium-offset-3">
          <input
            v-model="email"
            class="sign-up-form-input"
            type="text"
            required
            placeholder="Email" />
        </div>
        <div class="cell small-12 medium-6 medium-offset-3">
          <input
            v-model="phone"
            class="sign-up-form-input"
            type="text"
            required
            placeholder="Phone" />
        </div>
        <div class="cell small-12 medium-6 medium-offset-3">
          <input
            v-model="password"
            class="sign-up-form-input"
            type="password"
            required
            placeholder="Password" />
        </div>
        <div class="cell small-12 medium-6 medium-offset-3">
          <input
            v-model="passwordCheck"
            class="sign-up-form-input"
            type="password"
            required
            placeholder="Re-enter Password" />
            <p v-if="passwordAlert" class="text-red">
              <i>Entered passwords do not match</i>
            </p>
        </div>
        <div class="cell small-12 medium-6 medium-offset-3">
          <textarea
            v-model="comments"
            class="sign-up-form-input mt-5"
            placeholder="Your comments"></textarea>
          <div class="text-center">
            <button
              class="button rounded-tall">Submit</button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="logIn === 1" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h2>Log In</h2>
      </div>
      <div class="cell small-12 medium-6 medium-offset-3">
        <input
          v-model="email"
          class="sign-up-form-input"
          type="text"
          required
          placeholder="Email" />
      </div>
      <div class="cell small-12 medium-6 medium-offset-3">
        <input
          v-model="password"
          class="sign-up-form-input"
          type="password"
          required
          placeholder="Password" />
      </div>
      <div class="cell small-6 medium-3 medium-offset-3">
        <div class="text-center">
          <button
            @click="logInAction"
            class="button rounded-tall expanded">Log In</button>
        </div>
      </div>
      <div class="cell small-6 medium-3">
        <div class="text-center">
          <router-link to="/forgot-password">
            <span class="forgot-password">Forgot password</span>
            </router-link>
        </div>
      </div>
    </div>
    <div class="message" v-if="message.length > 0" @click="message = ''">
      {{ message }}
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SignUp',
  data() {
    return {
      logIn: 0,
      signUp: 0,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      comments: '',
      password: '',
      passwordCheck: '',
      passwordAlert: false,
      message: '',
    };
  },
  methods: {
    showSignUp() {
      this.logIn = 0;
      this.signUp = 1;
    },
    showLogIn() {
      this.logIn = 1;
      this.signUp = 0;
    },
    signUpAction() {
      if (this.password.length > 1 && this.password === this.passwordCheck) {
        const postData = {};
        postData.first_name = this.firstName;
        postData.last_name = this.lastName;
        postData.email = this.email;
        postData.phone = this.phone;
        postData.comments = this.comments;
        postData.basketId = this.$store.state.basketId;
        postData.password = this.password;
        axios.post('/customers/signUp.json', postData)
          .then((response) => {
            if (response.data === 'Customer Exists') {
              this.$emit('exists');
            } else {
              this.$store.dispatch('setCredentials', response.data);
              this.$store.commit('signedUp');
              this.$cookies.set('signedUp', 1);
              this.$store.commit('customer', response.data.customer);
              this.$cookies.set('customer', response.data.customer);
              this.$emit('loggedIn');
            }
          });
      } else {
        this.passwordAlert = true;
      }
    },
    logInAction() {
      const postData = {};
      postData.email = this.email;
      postData.password = this.password;
      axios.post('/customers/login.json', postData)
        .then((response) => {
          if (response.data === false) {
            this.message = 'Those details were not recognised. Please try again.';
          } else {
            this.$store.dispatch('setCredentials', response.data);
            this.$store.commit('signedUp');
            this.$cookies.set('signedUp', 1);
            this.$store.commit('customer', response.data.customer);
            this.$cookies.set('customer', response.data.customer);
          }
        });
    },
    checkDestination() {
      const destination = this.$store.state.profileDestination;
      if (destination === 'register') this.signUp = 1;
      if (destination === 'logIn') this.logIn = 1;
    },
  },
  mounted() {
    this.checkDestination();
  },
};
</script>
